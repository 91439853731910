@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.card {
  background: var(--Fourth-color) !important;
}

#Dashboard-Main {
  background: #2a2c3b !important;
  color: #9ca1b2;
}

#Dashboard-Main nav {
  width: 100%;
}
#Dashboard-Main nav,
#Dashboard-Main #sidebar-wrapper {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: #2f3242 !important;
  position: fixed;
}

#Dashboard-Main nav.navbar {
  z-index: 3000;
  height: 10vh !important;
}

#wrapper {
  padding-left: 190px;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 70px;
}

#page-content-wrapper {
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding: 10px;
  padding-top: 8vh;
}

/* *********** Notification  *********** */

.notification-container {
  position: relative;
}

.notification-bell {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.notification-bell span {
  position: absolute;
  top: -1px;
  right: -12px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unread {
  background-color: var(--Third-color) !important;
}

/* *********** Sidebar  *********** */
#Dashboard-Main #sidebar-wrapper {
  margin-top: 10vh;
  width: 190px;
  z-index: 1000;
  height: 100%;
  left: 0;
  transition: all 0.5s ease;
}

#Dashboard-Main #wrapper.toggled #sidebar-wrapper {
  width: 70px;
}

#Dashboard-Main .sidebar-toggle {
  position: fixed;
  top: 10vh;
  margin: 10px 0 0 200px;
  color: var(--Second-color);
  border: none;
  outline: none;
}

#Dashboard-Main #wrapper.toggled .sidebar-toggle {
  margin: 10px 0 0 80px;
}

.sidebar-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

#Dashboard-Main #wrapper.toggled .sidebar-nav {
  align-items: start;
}

#wrapper.toggled #sidebar-wrapper li {
  text-indent: 30px;
}

#wrapper #sidebar-wrapper li {
  text-indent: 30px;
  width: 100%;
}

.sidebar-nav li a {
  margin-top: 30px;
  text-decoration: none;
  color: var(--Third-color);
}

.sidebar-nav li a:hover,
.sidebar-nav li .active {
  color: var(--Fourth-color);
}

.sidebar-nav li a {
  display: flex;
  align-items: center;
  /* justify-self: start; */
}

.sidebar-nav li a i {
  font-size: 18px;
}

.sidebar-nav li span {
  display: inline-block;
}

#wrapper.toggled .sidebar-nav li span {
  display: none;
}

@media screen and (max-width: 768px) {
  #Dashboard-Main #wrapper {
    padding: 0;
  }

  #Dashboard-Main .sidebar-toggle {
    display: none;
  }
  #Dashboard-Main #sidebar-wrapper {
    width: 0px;
    overflow: hidden;
  }
  #Dashboard-Main #wrapper.toggled #sidebar-wrapper {
    width: 190px;
  }
  .sidebar-nav li span {
    display: none;
  }

  #wrapper.toggled .sidebar-nav li span {
    display: inline-block;
  }
}
/* *********** Intro Card *********** */

.introCard {
  border: 0;
  background-color: var(--Second-color) !important;
  height: 25vh;
  height: 100%;
}

.introCard .card-body {
  flex-wrap: wrap;
}

#Dashboard-Main h1 {
  color: var(--First-color);
}

.introCard h2 {
  color: var(--Fourth-color);
}

.introCard i {
  /* font-size: 100px; */
  color: var(--Fourth-color);
}

.introCard .icon-size {
  font-size: 100px;
  color: var(--Fourth-color);
}

/* Session Schedule */

.Charged .row {
  display: flex;
  justify-content: center;
}

.Charged .row .card {
  background-color: var(--Third-color) !important;
  color: var(--Fourth-color);
  margin: 10px;
  width: 260px;
}
.Charged .row .card-body {
  background-color: var(--Second-color) !important;
  color: var(--Fourth-color);
}
.Charged .row .card-body .card-text {
  font-size: 14px;
}

.session-schedule .session-tile {
  background-color: var(--Fourth-color);
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.12), -0 -0px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  font-size: 14px;
  overflow: hidden;
  margin: 20px 0;
}

.session-schedule .card {
  background: transparent !important;
  border: none;
}

.session-schedule .session-time {
  background-color: var(--Second-color) !important;
  padding: 5px 15px;
  color: var(--Third-color) !important;
}

.session-time h4,
.session-time span {
  color: var(--Fourth-color) !important;
}
.session-schedule .session-details {
  padding: 5px;
  padding: 0 10px;
  color: var(--Second-color);
}

.session-details h4 {
  color: var(--First-color) !important;
}
/*  Project Discription */

.project-discription {
  padding: 10px 0;
  margin: 20px 0;
}
.heading {
  margin: 20px 0;
  background-color: var(--Third-color);
  padding: 0 30px;
  border-radius: 10px;
  color: var(--First-color);
}
.project-discription ul {
  padding: 0 20px;
  color: var(--Fourth-color);
}
.project-discription ul li {
  display: flex;
}

.project-discription ul li::before {
  content: "";
  display: inline-block;
  min-width: 10px;
  height: 10px;
  border-radius: 2px;
  margin: 8px 13px;
  background-color: var(--Second-color);
  border: 2px solid var(--Fourth-color);
}
.project-discription ul li::marker {
  color: var(--Second-color);
}

.courses {
  padding: 10px 0;
  margin: 20px 0;
}

/* BlogForm */

.customForm {
  background-color: var(--Fourth-color);
  border-radius: 10px;
}

.customForm input,
.customForm textarea {
  background-color: var(--Fourth-color) !important;
  border: 2px solid var(--Third-color) !important;
  color: Black !important;
}

/* Inbox */

.inbox .heading {
  margin: 30px 0;
}

.inbox .accordion-item {
  border-radius: 10px;
  overflow: hidden;
}

.inbox .accordion-button {
  background-color: var(--Third-color);
  color: var(--First-color);
}

.inbox .accordion-button {
  display: flex !important;
  justify-content: space-between;
}

.inbox .accordion-button::after {
  display: none;
}

.inbox .accordion-body {
  border: none;
  background-color: var(--Fourth-color);
}

/* Status */

textarea {
  resize: none;
}

.previousUpdatesTable {
  overflow: auto;
}

.previousUpdatesTable::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: var(--Third-color);
}

.previousUpdatesTable::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: var(--Second-color);
}

.previousUpdatesTable table {
  border-radius: 10px !important;
  overflow: hidden;
  /* width: 90vw; */
}

.previousUpdatesTable table thead {
  background-color: var(--Second-color);
  border: solid var(--Second-color);
  color: var(--Fourth-color);
  border-radius: 10px !important;
}

.previousUpdatesTable table thead th {
  color: var(--Fourth-color);
  font-size: 14px;
}

.previousUpdatesTable table tbody {
  background-color: var(--Third-color);
  color: var(--First-color);
  text-align: center;
}

.previousUpdatesTable table td {
  max-width: 300px;
  text-wrap: wrap;
  font-size: 14px;
}
.previousUpdatesTable table tbody a {
  color: var(--First-color);
}

/* Styles for the outer container */

.smallImage-container {
  height: 100px;
  overflow: auto;
}

.smallImage-container::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: var(--Fourth-color);
  border-radius: 10px;
}

.smallImage-container::-webkit-scrollbar-thumb {
  background-color: var(--Second-color);
  border-radius: 10px;
}

.enlargedImageContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.enlargeInnerContainer {
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
  border: 5px solid var(--Fourth-color);
}

.enlargeInnerContainer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: var(--Fourth-color);
  border-radius: 10px;
}

.enlargeInnerContainer::-webkit-scrollbar-thumb {
  background-color: var(--Second-color);
  border-radius: 10px;
}

/* Styles for the enlarged image */
#enlarged-image {
  background-color: var(--Fourth-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
}

#enlarged-image img {
  width: 80vw;
}

/* ###### intern ############# */

.btn.reject {
  background-color: rgb(193, 0, 0) !important;
}
