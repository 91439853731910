@import url(https://unpkg.com/@webpixels/css@1.0/dist/index.css);

:root {
  --First-color: #242f9b;
  --Second-color: #646fd4;
  --Third-color: #9ba3eb;
  --Fourth-color: #dbdffd;
  --Fifth-color: #2a2c3b;
}

body {
  background-color: var(--Fifth-color) !important;
}

.btn {
  border: none;
  background-color: var(--Second-color) !important;
  color: var(--Fourth-color);
  border: none;
}

.btn:hover {
  background-color: var(--First-color) !important;
  color: var(--Fourth-color);
}

.btn-dark {
  background-color: var(--First-color) !important;
}

.btn-dark:hover {
  background-color: var(--Second-color) !important;
}

.text-color-Exdark {
  color: var(--Second-color) !important;
}

.text-color-dark {
  color: var(--Third-color) !important;
}

.text-color-light {
  color: var(--Fourth-color) !important;
}

.text-color-lg {
  color: var(--First-color) !important;
}

.text-color-sm {
  color: #5e5e5e !important;
}

/* ########## NavBar ############## */

.logo {
  width: 150px;
  /* filter: drop-shadow(1px 1px 1px #9ba3eb); */
}
nav {
  background-color: var(--Fourth-color) !important;
  height: 12vh;
  align-items: center;
}

li {
  position: relative;
}

.nav-item .nav-link {
  padding: 15px 20px;
  position: relative;
  color: var(--Second-color) !important;
}
.nav-item:hover .nav-link {
  color: var(--First-color) !important;
  animation: navlink-hover 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  position: relative;
}

@keyframes navlink-hover {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 8px;
  }
  100% {
    bottom: 0px;
  }
}

.nav-item:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 8px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background-color: var(--First-color) !important;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-item:hover:after {
  width: 100%;
  left: 0;
}

.nav-item .active {
  color: var(--First-color) !important;
}

.toggle-btn {
  background-color: transparent !important;
}

.toggle-btn .fa-moon {
  color: var(--Fourth-color);
}
.toggle-btn .fa-sun {
  color: var(--First-color);
}

.user-login .nav-item .nav-link:after {
  display: none;
}

.user-login-detail .nav-link {
  color: var(--Second-color) !important;
}

.user-login-detail .nav-link:hover {
  color: var(--First-color) !important;
}

.dropdown-menu {
  position: absolute;
  right: 0px !important;
  top: 55px !important;
  min-width: 100px !important;
  border-radius: 10px;
  background-color: var(--Fourth-color);
  border: none;
}
.dropdown-divider {
  width: 80%;
  border: 1px solid var(--Second-color);
  margin: 0 auto;
}
.dark-mode .dropdown-divider {
  border: 1px solid #3b3b3b;
}
.user-login .dropdown-menu {
  top: 60px !important;
  right: -20px !important;
}
.dropdown-item {
  color: var(--Second-color);
}
.user-login .dropdown-item {
  padding: 5px 20px !important;
}

.dark-mode .dropdown-menu {
  background-color: #1a202c !important;
}

.dark-mode .dropdown-item {
  color: var(--Fourth-color);
}
.dropdown-menu i {
  margin: 0 5px;
}
/* Custom Navbar Toggle Icon */
.navbar-toggler.custom-toggler .navbar-toggler-icon {
  display: none;
}

.navbar-toggler {
  background-color: var(--Second-color);
}

.navbar-toggler.custom-toggler .icon-bar {
  background-color: var(--Fourth-color);
  width: 25px;
  height: 3px;
  display: block;
  transition: all 0.2s;
  margin: 3px 0px;
  border-radius: 2px;
}
/* .navbar-toggler.custom-toggler .icon-bar:nth-of-type(1), */
.navbar-toggler.custom-toggler .icon-bar:nth-of-type(2) {
  width: 20px;
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(1) {
  transform: translateY(6px) rotate(45deg);
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.custom-toggler:not(.collapsed) .icon-bar:nth-of-type(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.hamburger {
  display: none !important;
}
.user-details {
  display: flex;
}

@media screen and (max-width: 992px) {
  .hamburger {
    display: flex !important;
  }
  .user-details {
    display: none;
  }
}

.form-select {
  background-color: var(--Fourth-color);
  border: 2px solid var(--Third-color);
}
