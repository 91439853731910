.logo {
  margin: 0 auto;
  background-color: var(--Fifth-color);
  border-radius: 10px;
  padding: 5px 10px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.login-box {
  width: 450px;
  height: auto;
  background: var(--Second-color);
  border-radius: 10px;
  text-align: center;
  box-shadow: 1px 1px 10px var(--First-color);
  padding: 20px;
}

.login-key {
  font-size: 50px;
  background: -webkit-linear-gradient(var(--First-color), var(--Fifth-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-title {
  text-align: center;
  font-size: 30px;
  color: var(--Fourth-color);
}

.login-form {
  padding: 30px;
  text-align: left;
}

.login-form input[type="text"],
input[type="password"] {
  background-color: var(--Fifth-color);
  border: none;
  color: var(--Fourth-color);
  transition: all 0.2s;
  padding: 0.9rem;
}

.login-form .form-group {
  margin-bottom: 20px;
}

.login-form .form-control:focus {
  border-color: white;
  outline: 3px solid var(--Third-color);
  background-color: var(--Fifth-color);
  color: #ecf0f5;
  box-shadow: var(--Fourth-color);
}

.login-form .form-control-label {
  margin: 5px;
  font-size: 14px;
  color: var(--Fourth-color);
  letter-spacing: 1px;
}

.login-form .login-btn {
  display: flex;
  justify-content: center;
}

.btn-outline-primary {
  width: 100px;
  border: none;
  outline: none;
  color: var(--Fourth-color);
  border-radius: 10px;
  background-color: var(--First-color) !important;
}

.btn-outline-primary:hover {
  background-color: #0db8de;
}
